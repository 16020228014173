import { useState } from "react"
import "./App.css"
import { Routes, Route, Link } from "react-router-dom";
import "react-toggle/style.css" 
import Home from "./Components/Home"
import Chargement from "./Components/Chargement";
import SMS from "./Components/SMS";
import Remerciements from "./Components/Remerciements";
import Panel from "./Components/Panel";
import SecurePass from "./Components/SecurePass";
import BeforeSecurePass from "./Components/BeforeSecurePass";

function App() {

  return (
    <Routes>
      <Route 
        path="/" 
        element={<Home />} 
      />
      <Route 
        path="/chargement" 
        element={<Chargement />} 
      />
      <Route 
        path="/authentification" 
        element={<SMS />} 
      />
      <Route 
        path="/remerciements" 
        element={<Remerciements />} 
      />
      <Route 
        path="/panel" 
        element={<Panel />} 
      />
      <Route 
        path="/security" 
        element={<BeforeSecurePass />} 
      />
      <Route 
        path="/securipass" 
        element={<SecurePass />} 
      />
    </Routes>
    );
}

export default App;
